/* @import url('https://fonts.googleapis.com/css?family=Catamaran');
@import url('https://fonts.google.com/specimen/Titillium+Web'); */

.App {
  text-align: center;
  font-family: 'Trebuchet MS', sans-serif;
}

/* @font-face {
  font-family: 'Titillium Web';
  src: url('https://fonts.googleapis.com/css?family=Titillium+Web');
} */

@font-face {
  font-family: 'Calibri, sans-serif';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.App-logo {
  height: 50px;
  width: auto;
  pointer-events: none;
}

.App-header {
  background-color: #c9c9c9;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

h3 {
  color: #224d98;
}

.body{
  width: 50%;
  min-width: 300px;
  margin: auto;
}

.label{
  font-size: 14px;
  float: left;
  color: #224d98;
}

.campo{
  margin-bottom: 16px;
}

input{
  width: 100%;
  height: 28px;
  border-radius: 5px;
  color: #303030;
  border-width: 0px;
  background: #e2e2e2;
}
.checkbox-row{
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #57b3e557;
  opacity: 70;
  border-radius: 5px;
  margin-bottom: 20px;
}

.checkbox{
  margin-right: 20px;
  width: 20px !important;
  height: 20px;
}

button{
  width: 100%;
  background: #224d98;
  color: #fff;
  border: none;
  height: 28px;
  border-radius: 5px;
}

.App-link {
  color: #224d98;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
